import React from "react";

const Faq = () => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
          Frequently asked questions
        </h2>
        <div className="mt-6 border-t-2 border-gray-100 pt-10">
          <dl className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Can I get a refund?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Absolutely. We&apos;re not in the business of making money
                    from unhappy customers. If you don&apos;t like FortWorx, or
                    it&apos;s not the right tool for you, let us know and we
                    will give you a full refund.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  What is in the annual subscription?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    All of FortWorx features, all updates for a year and private
                    support forum access.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Do you have a free tier?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    No, but if you&apos;re not happy with FortWorx or think
                    it&apos;s not the right tool for you, we will give you a
                    full refund.
                  </p>
                </dd>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div>
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Is FortWorx a service?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    No. FortWorx runs next to your own application (best in a
                    container). We want you to control the uptime, updates and
                    upgrades when it makes sense for you and your usecase.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Can I run FortWorx in an &quot;air gapped&quot; system?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Yes! FortWorx works even without internet access so you can
                    use it for your internal services on the most restricted
                    environments.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg leading-6 font-medium text-gray-900">
                  Is there a limit on the number of users I can use with
                  FortWorx?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    No. You can use FortWorx for a single application, on as
                    many environments (production, staging, test,...) and for as
                    many users or requests.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Faq;
