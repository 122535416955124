import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Benefits from "../components/benefits"
import Pricing from "../components/pricing"
import Faq from "../components/faq"
import Features from "../components/features"
import DiveIn from "../components/divein"

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `fortworx`,
          `account protection`,
          `account takeover`,
          `account management as a service`,
        ]}
        title="Home"
      />
      <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
        <div className="text-center">
          <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            Account Protection
            <br className="xl:hidden" />
            <span className="text-indigo-600 xl:ml-4">Made Easy</span>
          </h2>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Self-hosted Account Protection Built for Developers.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <a
                href="#"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </main>
      <Benefits></Benefits>
      <Features></Features>
      <Pricing></Pricing>
      <Faq></Faq>
      <DiveIn></DiveIn>
    </Layout>
  );
}

export default IndexPage;
